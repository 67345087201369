dmx.mediumEditor.InsertButton('image-upload', {

    contentDefault: '<b>IMG</b>',
    contentFA: '<i class="fa fa-camera"></i>',

    multiple: true,
    url: '',
    imageClass: '',
    figureClass: '',
    captionClass: '',
    buttons: ['imageLeft', 'imageCenter', 'imageRight', 'imageFull', 'imageDelete'],

    init: function() {
        dmx.mediumEditor.insertButton.prototype.init.apply(this, arguments);

        dmx.mediumEditor.states.figcaption = {
            buttons: ['bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', 'anchor', 'removeFormat'],
            checkState: function(node) {
                return node.nodeName == 'FIGCAPTION';
            }
        };

        dmx.mediumEditor.states.image = {
            buttons: this.buttons,
            checkState: function(node) {
                return node.nodeName == 'IMG';
            }
        }

        this.subscribe('editableClick', this.onClick.bind(this));
        this.subscribe('editableKeydownDelete', this.onDelete.bind(this));
    },

    onClick: function(event) {
        if (event.target && event.target.nodeName == 'IMG') {
            var range = this.document.createRange();
            range.selectNode(event.target);

            var selection  = this.document.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);

            this.base.checkContentChanged();

            /*var self = this;
            setTimeout(function() {
                var toolbar = self.base.getExtensionByName('imageToolbar');
                toolbar.showAndUpdateToolbar();
            }, 0);*/
        }
    },

    onDelete: function(event) {
        if (this.isImageSelected()) {
            event.preventDefault();
            this.deleteImage();
        }
    },

    setContent: function (html) {
        return html
            .replace(/<div([^>]*)>\s*<figure/gi, '<div$1 contenteditable="false"><figure')
            .replace(/<figcaption([^>]*)>/gi, '<figcaption$1 contenteditable="true">')
            .replace(/<\/div>\s*$/gi, '</div><p><br></p>');
    },

    getContent: function (html) {
        return html
            .replace(/contenteditable="[^"]*"/gi, '')
            .replace(/\s*<p[^>]*>\s*<br[^>]*>\s*<\/p[^>]*>\s*$/gi, '');
    },

    isImageSelected: function() {
        return MediumEditor.selection.getSelectedParentElement(MediumEditor.selection.getSelectionRange(this.document)).nodeName == 'IMG';
    },

    deleteImage: function() {
        var container = MediumEditor.util.getTopBlockContainer(MediumEditor.selection.getSelectionStart(this.document));
        var nextBlock = container.nextSibling;
        container.parentNode.removeChild(container);
        MediumEditor.selection.select(this.document, nextBlock);
        this.base.checkContentChanged();
    },

    insertHTML: function(elm) {
        var file = this.document.createElement('input');
        file.style.display = 'none';
        file.multiple = this.multiple;
        file.type = 'file';
        this.on(file, 'change', (function(event) {
            var files = event.target.files;

            for (var i = 0; i < files.length; i++) {
                var file = files[i];

                if (!this.multiple && files.length > 1) {
                    var alert = this.base.getExtensionByName('alert');
                    alert.show('Only 1 file please!');
                    return;
                }

                if (this.validateFile(file)) {
                    this.insertImage(elm, file);
                }
            }
        }).bind(this));
        this.getEditorOption('elementsContainer').appendChild(file);
        file.click();
    },

    insertImage: function(elm, file) {
        if (!this.url) {
            var reader = new FileReader();
            reader.onload = function(e) {
                elm.insertAdjacentHTML('beforebegin', this.createHtml(e.target.result));
                //this.base.checkContentChanged();
                this.setSelection();
            }.bind(this);
            reader.readAsDataURL(file);
        } else {
            var data = new FormData();
            data.append('image', file, file.name);

            var xhr = new XMLHttpRequest();
            xhr.open('POST', this.url, true);
            xhr.send(data);
            xhr.onload = (function() {
                if (xhr.status == 200) {
                    var resData = xhr.responseText;

                    try {
                        resData = JSON.parse(resData);
                    } catch(e) {
                        var alert = this.base.getExtensionByName('alert');
                        alert.show('Invalid response from server.');
                        return;
                    }
                    var src = dmx.parse('url', new dmx.DataScope(resData));
                    elm.insertAdjacentHTML('beforebegin', this.createHtml(src));
                    //this.base.checkContentChanged();
                    this.setSelection();
                } else {
                    var alert = this.base.getExtensionByName('alert');
                    alert.show('Error uploading image.');
                }
            }).bind(this);
        }
    },

    validateFile: function(file) {
        return !!file.type.match('image.*');
    },

    createHtml: function(src) {
        return '<div contenteditable="false" class="medium-editor-image-upload medium-editor-image-upload-center"><figure class="' + this.figureClass + '"><img class="' + this.imageClass + '" src="' + src + '"><figcaption class="' + this.captionClass + '" contenteditable="true" data-placeholder="Image caption"></figcaption></figure></div>';
    }

});
