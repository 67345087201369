dmx.mediumEditor.Extension('image-drag-drop', {

    init: function() {
        MediumEditor.Extension.prototype.init.apply(this, arguments);

        this.subscribe('addElement', this.onAddElement.bind(this));
        this.subscribe('editableDrag', this.onDrag.bind(this));
        this.subscribe('editableDrop', this.onDrop.bind(this));
    },

    onAddElement: function(event, elm) {
        this.on(elm, 'dragstart', this.onDragStart.bind(this));
    },

    onDragStart: function(event) {
        if (event.target.tagName == 'IMG') {
            var container = MediumEditor.util.getTopBlockContainer(event.target);

            if (container.classList.contains('medium-editor-image-upload')) {
                this.dragging = container;
                return;
            }
        }

        this.dragging = null;

        event.preventDefault();
        event.stopPropagation();
    },

    onDrag: function(event) {
        event.preventDefault();
        event.stopPropagation();

        if (event.dataTransfer.files.length) {
            event.dataTransfer.dropEffect = 'copy';
        }

        var target = MediumEditor.util.getTopBlockContainer(event.target);

        if (target) {
            target.classList.remove('medium-editor-image-upload-drop');

            if (event.type === 'dragover') {
                if (!MediumEditor.util.isMediumEditorElement(target)) {
                    target.classList.add('medium-editor-image-upload-drop');
                }
            }
        }
    },

    onDrop: function(event) {
        event.preventDefault();
        event.stopPropagation();

        var target = MediumEditor.util.getTopBlockContainer(event.target);

        if (target) {
            target.classList.remove('medium-editor-image-upload-drop');

            if (event.dataTransfer.files.length) {
                var imageUpload = this.base.getExtensionByName('image-upload');

                if (!imageUpload) {
                    var alert = this.base.getExtensionByName('alert');
                    alert.show('Error getting image upload extension.');
                    return;
                }


                if (!MediumEditor.util.isMediumEditorElement(target)) {
                    var files = event.dataTransfer.files;

                    if (!imageUpload.multiple && files.length > 1) {
                        var alert = this.base.getExtensionByName('alert');
                        alert.show('Only 1 file please!');
                        return;
                    }

                    for (var i = 0; i < files.length; i++) {
                        var file = files[i];

                        if (imageUpload.validateFile(file)) {
                            imageUpload.insertImage(target, file);
                        }
                    }
                }
            } else if (this.dragging) {
                target.parentNode.insertBefore(this.dragging, target);
            }
        }

        this.dragging = null;
    }

});
