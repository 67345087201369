dmx.mediumEditor.Button('imageCenter', {
    contentDefault: '<b>Center</b>',
    contentFA: '<i class="fa fa-align-center"></i>',
    action: 'image-center',
    aria: 'Align image center',

    init: function() {
        MediumEditor.extensions.button.prototype.init.call(this);
    },

    isAlreadyApplied: function(node) {
        return node.classList.contains('medium-editor-image-upload-center');
    },

    handleClick: function(event) {
        var container = MediumEditor.util.getTopBlockContainer(MediumEditor.selection.getSelectionStart(this.document));
        if (container.classList.contains('medium-editor-image-upload')) {
            container.classList.remove('medium-editor-image-upload-left','medium-editor-image-upload-right','medium-editor-image-upload-full');
            container.classList.add('medium-editor-image-upload-center');
        }
        this.updateToolbar();
    },

    updateToolbar: function() {
        var toolbar = this.base.getExtensionByName('image-toolbar');
        if (toolbar) {
            toolbar.checkState();
        }
    }
});
