dmx.mediumEditor.Button('imageDelete', {
    contentDefault: '<b>Remove</b>',
    contentFA: '<i class="fa fa-trash"></i>',
    action: 'delete-image',
    aria: 'Remove image',

    init: function() {
        MediumEditor.extensions.button.prototype.init.call(this);
    },

    handleClick: function(event) {
        var container = MediumEditor.util.getTopBlockContainer(MediumEditor.selection.getSelectionStart(this.document));
        var nextBlock = container.nextSibling;
        container.parentNode.removeChild(container);
        MediumEditor.selection.select(this.document, nextBlock);
        this.updateToolbar();
        this.base.checkContentChanged();
    },

    updateToolbar: function() {
        var toolbar = this.base.getExtensionByName('image-toolbar');
        if (toolbar) {
            toolbar.checkState();
        }
    }
});
