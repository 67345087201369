dmx.mediumEditor.Button('imageFull', {
    contentDefault: '<b>100%</b>',
    contentFA: '<i class="fa fa-align-justify"></i>',
    action: 'image-full',
    aria: 'Image full width',

    init: function() {
        MediumEditor.extensions.button.prototype.init.call(this);
    },

    isAlreadyApplied: function(node) {
        return node.classList.contains('medium-editor-image-upload-full');
    },

    handleClick: function(event) {
        var container = MediumEditor.util.getTopBlockContainer(MediumEditor.selection.getSelectionStart(this.document));
        if (container.classList.contains('medium-editor-image-upload')) {
            container.classList.remove('medium-editor-image-upload-left','medium-editor-image-upload-right','medium-editor-image-upload-center');
            container.classList.add('medium-editor-image-upload-full');
        }
        this.updateToolbar();
    },

    updateToolbar: function() {
        var toolbar = this.base.getExtensionByName('image-toolbar');
        if (toolbar) {
            toolbar.checkState();
        }
    }
});
